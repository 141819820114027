import { inject, Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpResponse,
} from '@angular/common/http';
import { debounceTime, delay, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthApiService {
  private readonly http = inject(HttpClient);

  public login(body: any): Observable<any> {
    return this.http.post('/auth/login', body);
  }

  public loginVerification(body: any): Observable<any> {
    return this.http.post('/verification/start/login', body);
  }

  public loginVerificationConfirmation(
    code: string,
    requestID: string | null,
  ): Observable<any> {
    return this.http.post('/verification/complete', {
      otp: code,
      requestID,
    });
  }
}
