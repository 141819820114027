import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProfileApiService } from '@common/services';
import { ProfileLoad, ProfileLoadError, ProfileLoadSuccess } from './actions';
import { catchError, exhaustMap, map, of } from 'rxjs';

@Injectable()
export class CabinetEffects {
  private readonly actions$ = inject(Actions);
  private readonly api = inject(ProfileApiService);

  public readonly profileLoad$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProfileLoad),
      exhaustMap(() =>
        this.api.load().pipe(
          map(({ email }: any) => ProfileLoadSuccess({ email })),
          catchError(() => of(ProfileLoadError())),
        ),
      ),
    ),
  );
}
