import { createAction, props } from '@ngrx/store';

export const ProfileLoad = createAction('[Profile] Load');
export const ProfileLoadSuccess = createAction(
  '[Profile] Load Success',
  props<{ email: string }>(),
);
export const ProfileLoadError = createAction('[Profile] Load Error');
export const ProfileReset = createAction('[Profile] Reset');

export const NotificationAdd = createAction(
  '[Notifications] Add',
  props<{ message: any }>(),
);
export const NotificationRemove = createAction(
  '[Notifications] Remove',
  props<{ index: number }>(),
);
